<template>
  <div id="offer-commission-slice">
    <app-sidebar-form
      form-name="offer-commission-slice"
      :edition="sliceEdition"
      :edition-mode-title="sliceEditionMode"
      :disabled="offerCommissionLoading"
      :title="$t('offer.commission.slice.title')"
      :entity="slice"
      @update:edition="$emit('editionUpdated', $event)"
      @update:entity="$emit('sliceUpdated', $event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- fromAmount -->
            <app-input
              id="offer-commission-slice-from-amount"
              v-model.number="data.item.fromAmount"
              :label="$t('offer.commission.slice.from_amount')"
              type="number"
              step="0.01"
              :overlay="offerCommissionLoading"
              :append="currency"
              rules="required|decimalOrInt"
            />
          </b-col>
          <b-col cols="12">
            <!-- toAmount -->
            <!-- TODO cross field validation -->
            <app-input
              id="offer-commission-slice-to-amount"
              v-model.number="data.item.toAmount"
              :label="$t('offer.commission.slice.to_amount')"
              type="number"
              step="0.01"
              :overlay="offerCommissionLoading"
              :append="currency"
              rules="decimalOrInt"
            />
          </b-col>
          <b-col cols="12">
            <!-- commissionRate -->
            <app-input
              id="offer-commission-slice-commission-rate"
              v-model.number="data.item.commissionRate"
              :label="$t('offer.commission.slice.commission_rate')"
              append="%"
              type="number"
              step="0.01"
              :overlay="offerCommissionLoading"
              rules="required|percentage"
            />
          </b-col>
          <b-col cols="12">
            <!-- amountType -->
            <enum-select
              id="offer-commission-slice-amount-type"
              v-model="data.item.amountType"
              enum-class="vATType"
              :label="$t('offer.commission.slice.amount_type')"
              :overlay="offerCommissionLoading"
              :clearable="false"
              required
            />
          </b-col>
          <b-col cols="12">
            <!-- fixedCommission -->
            <app-input
              id="offer-commission-slice-fixed-commission"
              v-model.number="data.item.fixedCommission"
              :label="$t('offer.commission.slice.fixed_commission')"
              :append="currency"
              type="number"
              step="0.01"
              :overlay="offerCommissionLoading"
              rules="required|decimalOrInt"
            />
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import getCurrency from '@/helpers/getCurrency'

export default {
  name: 'OfferPartCommissionSlice',

  components: {
    AppSidebarForm,
  },

  props: {
    sliceEdition: {
      type: Boolean,
      required: true,
    },
    sliceEditionMode: {
      type: String,
      default: undefined,
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
    offerCommissionLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currency: null,
    }
  },

  mounted() {
    this.currency = getCurrency()
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  min-width: 200px;
}
</style>
