<template>
  <div id="offer-commission-slices">
    <!-- TABLE -->
    <b-table
      ref="offer-commission-slices-table"
      class="position-relative table-dropdown-action"
      primary-key="id"
      :items="slices"
      :busy="offerCommissionLoading"
      :fields="slicesFields"
      :per-page="numberOfItemsPerPage"
      :current-page="page"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      responsive
    >
      <!-- LOADING -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <!-- EMPTY -->
      <template #empty>
        <div v-if="offerEdition" class="text-center text-primary my-2">
          <b-overlay :show="offerCommissionLoading">
            <b-button
              v-if="$can('OPERATOR_SUBSCRIPTION_ADD')"
              id="offer-commission-slice-add"
              variant="outline-primary"
              size="sm"
              @click="addSlice()"
            >
              <span class="text-nowrap">
                <feather-icon icon="PlusIcon" class="mr-25" />
                {{ $t('offer.commission.slice.add_new_slice') }}
              </span>
            </b-button>
          </b-overlay>
        </div>
        <div v-else class="text-center my-2">
          <span class="text-nowrap">
            {{ $t('common.no_record_found') }}
          </span>
        </div>
      </template>
      <!-- CAPTION -->
      <!-- validate -->
      <template v-if="offerEdition" #table-caption>
        <div class="text-center">
          <validation-provider
            #default="{ errors }"
            :name="$t('offer.commission.slice.title') + ' selection'"
            rules="required"
          >
            <b-form-group>
              <b-form-select id="cancellationPolicyConditionIf" v-model="slices" class="d-none" />
              <b-alert
                v-height-fade.appear
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="danger"
                :show="errors.length ? true : false"
                class="mb-0"
              >
                <div class="alert-body">
                  <feather-icon icon="AlertTriangleIcon" class="mr-50" />
                  <span>{{ errors[0] }}</span>
                </div>
              </b-alert>
            </b-form-group>
          </validation-provider>
        </div>
      </template>

      <!-- CELL -->
      <!-- fromAmount -->
      <template #cell(fromAmount)="data">
        <span>{{ data.item.fromAmount | priceFormat }}</span>
      </template>
      <!-- toAmount -->
      <template #cell(toAmount)="data">
        <span v-if="data.item.toAmount">{{ data.item.toAmount | priceFormat }}</span>
        <span v-else>-</span>
      </template>
      <!-- commissionRate -->
      <template #cell(commissionRate)="data">
        <span>{{ data.item.commissionRate | percentFormat }}</span>
      </template>
      <!-- fixedCommission -->
      <template #cell(fixedCommission)="data">
        <span>{{ data.item.fixedCommission | priceFormat }}</span>
      </template>
      <!-- action -->
      <template #cell(actions)="data">
        <!-- TODO fix display inline pb -->
        <div class="text-nowrap d-inline-block nowrap-l">
          <app-data-table-actions
            v-if="offerEdition"
            table-name="offer-commission-slices-table"
            :show-show="false"
            :show-duplicate="$can('OPERATOR_SUBSCRIPTION_ADD')"
            :show-edit="$can('OPERATOR_SUBSCRIPTION_EDIT')"
            :show-delete="$can('OPERATOR_SUBSCRIPTION_DELETE')"
            :show-add="$can('OPERATOR_SUBSCRIPTION_ADD')"
            @duplicate="duplicateSlice(data.item)"
            @edit="editSlice(data.item)"
            @delete="removeSlice(data.item.id)"
            @add="addSlice()"
          />
        </div>
      </template>
    </b-table>
    <!--BOTTOM-->
    <div class="mx-2 mb-2">
      <app-data-table-bottom
        table-name="offer-commission-slices-table"
        :page="page"
        :per-page="numberOfItemsPerPage"
        :total-count="totalItems"
        :items-length="slices.length"
        @pagination="page = $event"
      />
    </div>
    <OfferPartCommissionSlice
      :slice-edition="sliceEdition"
      :slice-edition-mode="sliceEditionMode"
      :slice="selectedSlice"
      :offer-commission-loading="offerCommissionLoading"
      @sliceUpdated="updateSlice($event)"
      @editionUpdated="sliceEdition = $event"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { heightFade } from '@core/directives/animations'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'

import {
  deleteOfferCommissionSliceRequest,
  patchOfferCommissionSliceRequest,
  postOfferCommissionSliceRequest,
} from '@/request/globalApi/requests/offerRequests'
import OfferPartCommissionSlice from './OfferPartCommissionSlice.vue'

export default {
  name: 'OfferPartCommissionSlices',

  components: {
    OfferPartCommissionSlice,
    AppDataTableActions,
    AppDataTableBottom,
  },

  directives: {
    'height-fade': heightFade,
  },

  props: {
    slices: {
      type: Array,
      required: true,
    },
    offerCommissionLoading: {
      type: Boolean,
      required: true,
    },
    offerId: {
      type: Number,
      default: null,
    },
    offerCommissionId: {
      type: Number,
      default: null,
    },
    offerEdition: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      sliceEdition: false,
      sliceEditionMode: '',
      selectedSlice: null,

      numberOfItemsPerPage: 5,
      page: 1,
      sortBy: 'commissionRate',
      isSortDirDesc: false,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang', 'dataTable']),

    totalItems() {
      return this.slices.length
    },

    slicesFields() {
      const fields = [
        {
          key: 'fromAmount',
          label: this.$t('offer.commission.slice.from_amount'),
          sortable: true,
        },
        {
          key: 'commissionRate',
          label: this.$t('offer.commission.slice.commission_rate'),
          sortable: true,
        },
        {
          key: 'toAmount',
          label: this.$t('offer.commission.slice.to_amount'),
          sortable: true,
        },
        {
          key: 'amountType',
          label: this.$t('offer.commission.slice.amount_type'),
          sortable: true,
        },
        {
          key: 'fixedCommission',
          label: this.$t('offer.commission.slice.fixed_commission'),
          sortable: true,
        },
      ]
      if (this.offerEdition) {
        fields.push({
          key: 'actions',
          label: this.$tc('action.title', 2),
          thClass: 'text-right',
          tdClass: 'text-right',
        })
      }
      return fields
    },
  },

  methods: {
    addSlice() {
      this.selectedSlice = {
        id: null,
      }
      this.sliceEditionMode = 'add'
      this.sliceEdition = true
    },

    editSlice(slice) {
      this.selectedSlice = slice
      this.sliceEditionMode = 'edit'
      this.sliceEdition = true
    },

    duplicateSlice(slice) {
      this.selectedSlice = { ...slice, id: null }
      this.sliceEditionMode = 'duplicate'
      this.sliceEdition = true
    },

    removeSlice(id) {
      deleteOfferCommissionSliceRequest(this.offerId, this.offerCommissionId, id).then(response => response && this.$emit('slicesUpdated'))
    },

    updateSlice(slice) {
      if (this.sliceEditionMode === 'edit') {
        patchOfferCommissionSliceRequest(this.offerId, this.offerCommissionId, slice).then(() => {
          this.$emit('slicesUpdated')
        })
      } else {
        postOfferCommissionSliceRequest(this.offerId, this.offerCommissionId, slice).then(() => {
          this.$emit('slicesUpdated')
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
</style>
